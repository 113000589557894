// @file
// Custom sass mixins
//
// Define the custom mixins for your project here.
// http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#defining_a_mixin

// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    clip: rect(1px 1px 1px 1px); // IE6 and IE7 use the wrong syntax.
  }
  clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

@mixin box-shadow-horizontal(){
  box-shadow: #aaa 7px 0 17px 1px, #aaa -7px 0 17px 1px;
}

@mixin no-bullets {
  li {
    list-style-type: none;
    list-style-image: none;
  }
}

@mixin clean-list {
  @include no-bullets;
  margin-left: 0;
  padding-left: 0;
}

@mixin clearfix {
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin horizontal-list($list-margin: 1em, $list-item-margin-right: .25em, $list-item-margin-bottom: .25em) {
    list-style-type:none;
    padding:0;
    margin: $list-margin;
    overflow:hidden;
    > li{
        display:block;
        float:left;
        margin-right: $list-item-margin-right;
        margin-bottom: $list-item-margin-bottom;
        &:last-child{
            margin-right:0px;
        }
    }
}

// hide-text
@mixin hide-text() {
  border:0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
  background-color: transparent;
}

// Used to create equilateral triangles
@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;
  @if ($triangle-direction == top) {
    border-color: $triangle-color transparent transparent transparent;
    border-top-style: solid;
  }
  @if ($triangle-direction == bottom) {
    border-color: transparent transparent $triangle-color transparent;
    border-bottom-style: solid;
  }
  @if ($triangle-direction == left) {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
  }
  @if ($triangle-direction == right) {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
  }
}

@mixin red-button {
  display: inline-block;
  border: 1px solid $red-button-dark-red;
  font-size: 13px;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 17px;
  padding: 2px 14px;
  color: #fff;
  transition: all 0.2s ease-in;
  text-shadow: darken($red-button-dark-red, 40%), 1px, 1px, 0;
  &:hover {
    background-color: $red-button-light-red;
  }
 }

 @mixin submit-btn($btn-color: $primary-color, $btn-radius: $button-border-radius, $btn-padding: $gutter/4 $gutter/2, $font-size: 13px) {
  background-color: $btn-color;
  // background: linear-gradient($btn-color 0, darken($btn-color, 10%) 100%);
  color: white;
  font-weight: normal;
  // letter-spacing: .05em;
  // background-repeat: repeat-x;
  // border: 1px solid darken($btn-color, 10%);
  border: 0px;
  border-radius: $btn-radius;
  // box-shadow: rgba(255, 255, 255, 0.2) 0 0 8px inset, rgba(0, 0, 0, 0.05) 0 1px 2px;
  font-size: $font-size;
  text-transform: uppercase;
  // text-shadow: 0 1px 1px darken($btn-color, 20%);
  text-align: center;
  display: inline-block;
  line-height: 0;
  padding: $btn-padding;
  font-family: $oswald;
  font-weight: normal;
  &:hover, &:active, &:focus {
    text-decoration: none;
    // border: 1px solid darken($btn-color, 10%);
    background-color: darken($btn-color, 10%);
    // background-image:linear-gradient(center top, darken($btn-color, 10%), $btn-color);
    // box-shadow: rgba(255, 255, 255, 0.4) 0 0 8px inset;
  }
}

@mixin wireframe-btn {
  text-shadow: none;
  border: 1px solid #ccced0;
  text-transform: uppercase;
  background: white;
  &:hover, &:active, &:focus {
    color: $color-orange;
    border: 1px solid $color-orange;
  }
}

@mixin basic-btn($basic-btn-color: #000000, $radius: 16px) {
  background-color: $basic-btn-color;
  color: lighten($basic-btn-color, 60%);
  font-weight: bold;
  background-repeat: repeat-x;
  border: 1px solid lighten($basic-btn-color, 10%);
  border-radius: $radius;
  box-shadow: rgba(255, 255, 255, 0.1) 0 0 8px inset, rgba(0, 0, 0, 0.5) 0 1px 2px;
  font-size: .75em;
  text-transform: uppercase;
  text-shadow: 0 1px 1px darken($basic-btn-color, 20%);
  text-align: center;
  display: inline-block;
  line-height: $base-font-size * 1.5;
  height: $base-font-size * 1.5;
  padding: 0 4%;
  &:hover {
    color: lighten($basic-btn-color, 60%);
    text-decoration: none;
    background-image: linear-gradient(center top, darken($basic-btn-color, 5%), $basic-btn-color);
  }
  &:active, &:focus {
    background-color: darken($basic-btn-color, 10%);
    color: lighten($basic-btn-color, 60%);
  }
  &:visited {
    color: lighten($basic-btn-color, 60%);
  }
}

@mixin gradient-title ($dark-color: #dddddd, $light-color: #ffffff) {
    background-color: $dark-color;
    background-image: linear-gradient(to right, $dark-color, $light-color, $dark-color);
}


// Learning center slideshow modal: /en/learning-center/case-studies
// Footer newsletter signup modal
@mixin modal-styling() {
  border-radius: 0;
  border-top: 2px solid #eaeaea;
  padding-top: 12px;
  margin-top: 0;
  margin-bottom: 14px;
  margin-right: 14px;
  margin-left: 14px;
}

@mixin container {
  max-width: 1080px;
  padding-left: $gutter;
  padding-right: $gutter;
  margin: 0 auto;
}

@mixin break-container {
  width: 100vw;
  margin-left: calc(-100vw / 2 + 1040px / 2) !important;
  margin-right: calc(-100vw / 2 + 1040px / 2) !important;
  @include breakpoint($desktop-max) {
    margin-left: -$gutter !important;
    margin-right: -$gutter !important;
  }
}

@mixin kicker($kicker-color: $tertiary-color) {
  color: $kicker-color;
  font-family: $source-sans-pro;
  text-transform: uppercase;
  font-size: 1.25em;
  font-weight: bold;
}

@mixin absolute-position($top:auto, $right:auto, $bottom:auto, $left:auto) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
