/* Import Sass mixins, variables, Compass modules, etc. */
@import "_base";

/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * https://drupal.org/node/1707736
 */

/**
 * Wireframes.
 */
.with-wireframes {
  #header,
  #main,
  #content,
  #navigation,
  .region-sidebar-first,
  .region-sidebar-second,
  #footer,
  .region-bottom {
    outline: 1px solid #ccc;

    @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
      .lt-ie8 & {
        /* IE6/7 do not support the outline property. */
        border: 1px solid #ccc;
      }
    }
  }
}

/**
 * Accessibility features.
 */

/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible,
%element-invisible {
  @include element-invisible;
}

/* Turns off the element-invisible effect. */
%element-invisible-off {
  @include element-invisible-off;
}

.element-focusable,
%element-focusable {
  @extend %element-invisible;

  &:active,
  &:focus {
    @extend %element-invisible-off;
  }
}

/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 */
#skip-link {
  margin: 0;

  a,
  a:visited {
    display: block;
    width: 100%;
    padding: 2px 0 3px 0;
    text-align: center;
    background-color: #666;
    color: #fff;
  }
}

/**
 * Branding header.
 */

/* Wrapping link for logo. */
.header__logo {
  float: left; /* LTR */
  margin: 0;
  padding: 0;
}

/* Logo image. */
.header__logo-image {
  vertical-align: bottom;
}

/* Wrapper for website name and slogan. */
.header__name-and-slogan {
  float: left;
}

/* The name of the website. */
.header__site-name {
  margin: 0;
  @include adjust-font-size-to( $h1-font-size );
}

/* The link around the name of the website. */
.header__site-link {
  &:link,
  &:visited {
    color: #000;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

/* The slogan (or tagline) of a website. */
.header__site-slogan {
  margin: 0;
}

/* The secondary menu (login, etc.) */
.header__secondary-menu {
  float: right; /* LTR */
}

/* Wrapper for any blocks placed in the header region. */
.header__region {
  /* Clear the logo. */
  clear: both;
}

/**
 * Navigation bar.
 */
#navigation {
  /* Sometimes you want to prevent overlapping with main div. */
  /* overflow: hidden; */

  .block {
    margin-bottom: 0;
  }

  .block-menu .block__title,
  .block-menu-block .block__title {
    @extend %element-invisible;
  }

  /* Main menu and secondary menu links and menu block links. */
  .links,
  .menu {
    margin: 0;
    padding: 0;
    // text-align: left; /* LTR */

    // li {
    //   /* A simple method to get navigation links to appear in one line. */
    //   float: left; LTR
    //   padding: 0 10px 0 0; /* LTR */
    //   list-style-type: none;
    //   list-style-image: none;
    // }
  }
}

// Sidebar Menus

#block-views-distributors-block,
#block-menu-block-4 {
  .block-title {
    color: white;
    border-color: #999;
    background: #d1d1d1;
    font-size: 15px;
    padding:0 0 0 7px;
    font-weight: normal;
    font-weight: 400;
    @include single-text-shadow(#999, 1px, 1px, 0);
  }
  .menu, .ui-list {
    margin-left: 0;
    padding-left: 0;
    li {
      list-style: none;
      list-style-type: none;
    }
    a {
      display: block;
      padding-left: 7px;
      margin-bottom: 5px;
      color: $body-font-color;
      &:visited {
        color: $body-font-color;
        &:hover {
          color: white;
        }
      }
      &:hover, &:active {
        background-color: $color-orange;
        color: white;
      }
      &.active {
        background: #f1f1f1;
      }
    }
  }
}


/**
 * Breadcrumb navigation.
 */
.breadcrumb {
  ol {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

/**
 * Titles.
 */
.page__title,
.node__title,          /* Title of a piece of content when it is given in a list of content. */
.block__title,         /* Block title. */
.comments__title,      /* Comment section heading. */
.comments__form-title, /* Comment form heading. */
.comment__title {      /* Comment title. */
  margin: 0;
}

.page__title,
.section-title {
  margin-top: 10px;
}


.block-title, .block__title {
    // color: $color-orange;
    // font-size: 19px;
    padding-bottom: 5px;
    // border-bottom: 1px solid $color-orange;
    margin-bottom: 6px;
}

/**
 * Messages.
 */
.messages {
  margin: 0 0 $gutter;
  padding: 10px 10px 10px 50px; /* LTR */
  background-image: inline-image("message-24-ok.png");
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    *background-image: image-url("message-24-ok.png");
  }
  background-position: 8px 8px; /* LTR */
  background-repeat: no-repeat;
  border: 1px solid #be7;
}
.messages--status {
  @extend .messages;
  @extend %ok;
}
.messages--warning {
  @extend .messages;
  @extend %warning;
  background-image: inline-image("message-24-warning.png");
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    *background-image: image-url("message-24-warning.png");
  }
  border-color: #ed5;
}
.messages--error {
  @extend .messages;
  @extend %error;
  background-image: inline-image("message-24-error.png");
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    *background-image: image-url("message-24-error.png");
  }
  border-color: #ed541d;

}
.messages__list {
  margin: 0;
}
.messages__item {
  list-style-image: none;
}

/* Core/module installation error messages. */
.messages--error  p.error {
  color: #333;
}

/* System status report. */
.ok,
%ok {
  background-color: #f8fff0;
  color: #234600;
}
.warning,
%warning {
  background-color: #fffce5;
  color: #840;
}
.error,
%error {
  background-color: #fef5f1;
  color: #8c2e0b;
}

/**
 * Tabs.
 */

/* Basic positioning styles shared by primary and secondary tabs. */
%tabs {
  @include clearfix;
  // background-image: linear-gradient(to top, $tabs-border 1px, transparent 1px);
  /* IE 9 and earlier don't understand gradients. */
  list-style: none;
  // border-bottom: 1px solid $tabs-border \0/ie;
  // margin: rhythm(1) 0;
  // padding: 0 2px;
  // white-space: nowrap;
}
%tabs__tab {
  float: left; /* LTR */
  // margin: 0 3px;
}
%tabs__tab-link {
  // border: 1px solid #e9e9e9;
  // border-right: 0;
  border-bottom: 0;
  display: block;
  // @include adjust-leading-to(1);
  text-decoration: none;
}

/* Primary tabs. */
// .tabs-primary {
//   @extend %tabs;
// }
// .tabs-primary__tab {
//   @extend %tabs__tab;
//   @include border-top-radius(4px);
//   @include single-text-shadow(#fff, 1px, 1px, 0);
//   border: 1px solid $tabs-border;
//   border-bottom-color: transparent;
//   /* IE 9 and earlier don't understand gradients. */
//   border-bottom: 0 \0/ie;
// }
// .tabs-primary__tab.is-active {
//   @extend .tabs-primary__tab;
//   border-bottom-color: $tabs-container-bg;
// }

// We use 3 placeholder styles to prevent @extend from going selector crazy.
%tabs-primary__tab-link {
  @extend %tabs__tab-link;
  border-top-radius: 4px;
  // transition: background-color 0.3s;
  // color: #333;
  // background-color: #dedede;
  letter-spacing: 1px;
  padding: 0 1em;
  text-align: center;
}
%tabs-primary__tab-link-is-hover {
  // background-color: #e9e9e9;
  // border-color: #f2f2f2;
}
%tabs-primary__tab-link-is-active {
  // background-color: transparent;
  // @include filter-gradient(rgba(#e9e9e9, 1), rgba(#e9e9e9, 0));
  // background-image: linear-gradient(rgba(#e9e9e9, 1), rgba(#e9e9e9, 0));
  // border-color: #fff;
}

// a.tabs-primary__tab-link {
//   @extend %tabs-primary__tab-link;

//   &:hover,
//   &:focus {
//     @extend %tabs-primary__tab-link-is-hover;
//   }
//   &:active {
//     @extend %tabs-primary__tab-link-is-active;
//   }
// }
// a.tabs-primary__tab-link.is-active {
//   @extend %tabs-primary__tab-link;
//   @extend %tabs-primary__tab-link-is-active;
// }

/* Secondary tabs. */
.tabs-secondary {
  @extend %tabs;
  font-size: .9em;
  /* Collapse bottom margin of ul.primary. */
  margin-top: -(rhythm(1));
}
.tabs-secondary__tab,
.tabs-secondary__tab.is-active {
  @extend %tabs__tab;
  margin: rhythm(1)/2 3px;
}

// We use 3 placeholder styles to prevent @extend from going selector crazy.
%tabs-secondary__tab-link {
  @extend %tabs__tab-link;
  border-radius: .75em;
  transition: background-color 0.3s;
  text-shadow: #fff, 1px, 1px, 0;
  background-color: #f2f2f2;
  color: #666;
  padding: 0 .5em;
}
%tabs-secondary__tab-link-is-focus {
  background-color: #dedede;
  border-color: #999;
  color: #333;
}
%tabs-secondary__tab-link-is-active {
  @include single-text-shadow(#333, 1px, 1px, 0);
  background-color: #666;
  border-color: #000;
  color: #fff;
}

a.tabs-secondary__tab-link {
  @extend %tabs-secondary__tab-link;

  &:hover,
  &:focus {
    @extend %tabs-secondary__tab-link-is-focus;
  }
  &:active {
    @extend %tabs-secondary__tab-link-is-active;
  }
}
a.tabs-secondary__tab-link.is-active {
  @extend %tabs-secondary__tab-link;
  @extend %tabs-secondary__tab-link-is-active;
}



/**
 * Inline styles.
 */

/* List of links generated by theme_links(). */
.inline {
  display: inline;
  padding: 0;

  li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0; /* LTR */
  }
}

/* The inline field label used by the Fences module. */
span.field-label {
  padding: 0 1em 0 0; /* LTR */
}

/**
 * "More" links.
 */
.more-link {
  text-align: right; /* LTR */
}
.more-help-link {
  text-align: right; /* LTR */
}
.more-help-link a {
  background-image: inline-image("help.png");
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    *background-image: image-url("help.png");
  }
  background-position: 0 50%; /* LTR */
  background-repeat: no-repeat;
  padding: 1px 0 1px 20px; /* LTR */
}

/**
 * Pager.
 */

/* A list of page numbers when more than 1 page of content is available. */
.pager {
  clear: both;
  padding: 0;
  text-align: center;
}
%pager__item {
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
  background-image: none;
}

.pager-item,      /* A list item containing a page number in the list of pages. */
.pager-first,     /* The first page's list item. */
.pager-previous,  /* The previous page's list item. */
.pager-next,      /* The next page's list item. */
.pager-last,      /* The last page's list item. */
.pager-ellipsis { /* A concatenation of several list items using an ellipsis. */
  @extend %pager__item;
}

/* The current page's list item. */
.pager-current {
  @extend %pager__item;
  font-weight: bold;
}

/**
 * Blocks.
 */

/* Block wrapper. */
.block {
  margin-bottom: rhythm(1);
}

/**
 * Menus.
 */
.menu__item.is-leaf {
  list-style-image: inline-image("menu-leaf.png");
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    *list-style-image: image-url("menu-leaf.png");
  }
  list-style-type: square;
}
.menu__item.is-expanded {
  list-style-image: inline-image("menu-expanded.png");
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    *list-style-image: image-url("menu-expanded.png");
  }
  list-style-type: circle;
}
.menu__item.is-collapsed {
  list-style-image: inline-image("menu-collapsed.png"); /* LTR */
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    *list-style-image: image-url("menu-collapsed.png"); /* LTR */
  }
  list-style-type: disc;
}

/* The active item in a Drupal menu. */
.menu a.active {
  color: #000;
}

/**
 * Marker.
 */

/* The "new" or "updated" marker. */
.new,
.update {
  color: #c00;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
}

/**
 * Unpublished note.
 */

/* The word "Unpublished" displayed underneath the content. */
.unpublished {
  height: 0;
  overflow: visible;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  /* A very nice CSS3 property. */
  word-wrap: break-word;
}
@if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
  .lt-ie8 .node-unpublished>*,
  .lt-ie8 .comment-unpublished>* {
    /* Otherwise these elements will appear below the "Unpublished" text. */
    position: relative;
  }
}

/**
 * Comments.
 */

/* Wrapper for the list of comments and its title. */
.comments {
  margin: rhythm(1) 0;
}

/* Preview of the comment before submitting new or updated comment. */
.comment-preview {
  /* Drupal core will use a #ffffea background. See #1110842. */
  background-color: #ffffea;
}

/* Wrapper for a single comment. */
.comment {

  /* Comment's permalink wrapper. */
  .permalink {
    text-transform: uppercase;
    font-size: 75%;
  }
}

/* Nested comments are indented. */
.indented {
  /* Drupal core uses a 25px left margin. */
  margin-left: $indent-amount;
}

/**
 * Forms.
 */

/* Wrapper for a form element (or group of form elements) and its label. */
.form-item {
  margin: 0 0 12px 0;

  /* Pack groups of checkboxes and radio buttons closer together. */
  .form-checkboxes & ,
  .form-radios & {
    /* Drupal core uses "0.4em 0". */
    margin: 0;
  }

  /* Form items in a table. */
  tr.odd &,
  tr.even & {
    margin: 0;
  }

  /* Highlight the form elements that caused a form submission error. */
  input.error,
  textarea.error,
  select.error {
    border: 1px solid #c00;
  }

  /* The descriptive help text (separate from the label). */
  .description {
    font-size: 0.85em;
  }
}

.form-type-radio,
.form-type-checkbox {
  .description {
    margin-left: 2.4em;
  }
}

/* The part of the label that indicates a required field. */
.form-required {
  color: #c00;
}

/* Labels for radios and checkboxes. */
label.option {
  display: inline;
  font-weight: normal;
}

/* Buttons used by contrib modules like Media. */
a.button {
  @include appearance(button);
}

/* Password confirmation. */
.password-parent,
.confirm-parent {
  margin: 0;
}

/* Drupal's default login form block. */
#user-login-form {
  text-align: left; /* LTR */
}

/**
 * OpenID
 *
 * The default styling for the OpenID login link seems to assume Garland's
 * styling of list items.
 */

/* OpenID creates a new ul above the login form's links. */
.openid-links {
  /* Position OpenID's ul next to the rest of the links. */
  margin-bottom: 0;
}

/* The "Log in using OpenID" and "Cancel OpenID login" links. */
.openid-link,
.user-link {
  margin-top: rhythm(1);
}
html.js #user-login-form li.openid-link,
#user-login-form li.openid-link {
  /* Un-do some of the padding on the ul list. */
  margin-left: -20px; /* LTR */
}
#user-login ul {
  margin: rhythm(1) 0;
}

/**
 * Drupal admin tables.
 */
@if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
  .lt-ie8 tr.even,
  .lt-ie8 tr.odd {
    th,
    td {
      /* IE doesn't display borders on table rows. */
      border-bottom: 1px solid #ccc;
    }
  }
}

/* Markup generated by theme_tablesort_indicator(). */
td.active {
  background-color: #ddd;
}

/* Center checkboxes inside table cell. */
td.checkbox,
th.checkbox {
  text-align: center;
}

/* Drupal core wrongly puts this in system.menus.css. Since we override that, add it back. */
td.menu-disabled {
  background: #ccc;
}

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */

/* Suggestion list. */
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
.fieldset-legend {
  html.js .collapsible & {
    background-image: inline-image("menu-expanded.png");
    @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
      *background-image: image-url("menu-expanded.png");
    }
    background-position: 5px 65%; /* LTR */
    background-repeat: no-repeat;
  }
  html.js .collapsed & {
    background-image: inline-image("menu-collapsed.png"); /* LTR */
    @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
      *background-image: image-url("menu-collapsed.png"); /* LTR */
    }
    background-position: 5px 50%; /* LTR */
  }
  .summary {
    color: #999;
    font-size: 0.9em;
    margin-left: 0.5em;
  }
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
tr.drag {
  background-color: #fffff0;
}
tr.drag-previous {
  background-color: #ffd;
}
.tabledrag-toggle-weight {
  font-size: 0.9em;
}

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffc;
}

/**
 * Progress bar.
 *
 * @see progress.js
 */
.progress {
  font-weight: bold;

  .bar {
    background: #ccc;
    border-color: #666;
    margin: 0 0.2em;
    @include border-radius(3px);
  }
  .filled {
    background-color: #0072b9;
    background-image: image-url("progress.gif");
  }
}

/**
 * Multiple paragraph spacing
 */
p + p{
  margin: 12px 0 0 0;
}
